<script>
/*
plans: [
        {
          title: "Starter",
          price: 39,
          feature: ["Full Access", "Source Files", "Free Appointments"],
          button: "Get Started",
          isBest: true,
        },
      ]
 */
export default {
  props: {
    plans: {
      default: [],
      type: Array
    },
    isIncluded: {
      default: false,
      type: Boolean
    },
    isChoosable: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
			orderPlans: [
				'waiting',
				'gmail',
				'shader'
			],
      isLoading: false
    };
  },
  mounted() {
    this.isLoading = this.plans.length === 0;
  },
	computed: {
		sortedPlans() {
			let plans = [];
			if (this.plans.length === 0) return plans;

			this.orderPlans.forEach(plan => {
				plans.push(this.plans.find(element => element.slug === plan));
			})

			return plans;
		}
	},
  watch: {
    plans: function () {
      this.isLoading = false;
    }
  }
};
</script>

<template>
  <div class="d-flex justify-content-center mt-4 prices" v-if="isLoading">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
  <div v-else class="row d-flex justify-content-center prices">
    <div
      class="col-lg-3 col-md-6 col-12 mt-4 pt-2"
      :class="isIncluded ? 'col-lg-6' : 'col-lg-3'"
      v-for="(item, index) of sortedPlans"
      :key="index"
    >
      <div
        class="card pricing-rates business-rate shadow bg-light border-0 rounded h-100"
        :class="item.isBest ? 'bg-white' : 'bg-light'"
      >
        <div
          class="ribbon ribbon-right ribbon-warning overflow-hidden"
          v-if="item.isBest"
        >
          <span class="text-center d-block shadow small h6">Best</span>
        </div>
        <div class="card-body d-flex justify-content-between flex-column">
          <h6 class="title font-weight-bold text-uppercase text-primary">
            {{ item.title }}
          </h6>
          <div class="d-flex mb-0">
            <span class="h4 mb-0 mt-2">$</span>
            <span class="price h1 mb-0">{{ item.price }}</span>
            <span class="h4 align-self-end mb-1">/mo</span>
          </div>
          <div style="min-height: 60px" class="mb-3 mt-0">
            <div v-if="item.price_description">
              <span>{{ item.price_description }}</span>
            </div>
            <div v-else-if="item.price_for_others">
              <span>
                <span class="h5">+ ${{item.price_for_others}}</span>/mo for each additional users
              </span>
            </div>
          </div>

          <ul class="list-unstyled mb-4 pl-0" v-if="!isIncluded">
            <li
              v-for="(feature, index) in item.feature"
              class="h6 text-muted mb-0"
              :key="index"
            >
              <span class="text-primary h5 mr-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >{{ feature }}
            </li>
          </ul>
          <div class="d-flex justify-content-center">
            <button class="btn"
                    :class="$route.query.slug === item.slug ? 'btn-success' : 'btn-primary'"
                    :disabled="$route.query.slug === item.slug"
                    @click="$router.push({name: 'buy', query: {'slug': item.slug}})">
              <template v-if="isChoosable">
                <span v-if="$route.query.slug === item.slug">Selected</span>
                <span v-else>Choose</span>
              </template>
              <span v-else>{{ item.button }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
</template>

<style scoped>
.prices {
  min-height: 150px;
}
</style>
